<template>
  <b-card title="Faq">

    <b-tabs>
      <b-tab
        active
        @click="$router.push(`/add-faq/${id}`)"
      >
     
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Faq</span>
        </template>
        <Addfaq />
      </b-tab>
      <b-tab @click="$router.push(`/all-country-faq/${id}`)">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>  Faq</span>
        </template>
        <router-view />
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import Addfaq from './Addfaq.vue'
import { useRouter } from '@core/utils/utils'

export default {
  setup(props) {

    const { route } = useRouter()
    const { id } = route.value.params
    return {
      id,
    }
  },
  components: {
    BCard,

    BTabs,
    BTab,

    Addfaq,
  },
  data() {
    return {

    }
  },
}
</script>
